import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CandidateApplicationConnectData } from './types';
import { UseMutation } from 'types/types';
import { createApplicationFromConnect } from './queries';

export const useCreateConnectATSApplicationMutation: UseMutation<
  CandidateApplicationConnectData,
  unknown,
  CandidateApplicationConnectData,
  unknown
> = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (payload) => {
      return createApplicationFromConnect(payload).then((res) => {
        return {
          ...(res.data as CandidateApplicationConnectData),
          title: payload.title,
          internal_candidate_note_content: payload.internal_candidate_note_content,
          schools: payload.schools,
        };
      });
    },

    onSuccess: (data, variables, context) => {
      const queryKey = ['roleId', variables.role_id, 'candidateId', variables.candidate_id];

      const updatedData = { has_existing_app: true };
      queryClient.setQueryData(queryKey, updatedData);

      if (options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};
