export enum ActionTypes {
  SET_PAGE = 'SET_PAGE',
  SET_QUERY = 'SET_QUERY',
  SET_FILTER = 'SET_FILTER',
  SET_FILTER_IS_ACTIVE = 'SET_FILTER_IS_ACTIVE',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_USE_DEBOUNCE = 'SET_USE_DEBOUNCE',
  SET_EXPRESS_INTEREST_MODAL_IS_OPEN = 'SET_EXPRESS_INTEREST_MODAL_IS_OPEN',
  SET_EXPRESS_INTEREST_EXIT_MODAL_IS_OPEN = 'SET_EXPRESS_INTEREST_EXIT_MODAL_IS_OPEN',
  SET_SELECTED_SCHOOL = 'SET_SELECTED_SCHOOL',
  SET_MORE_INFO_SLIDER_IS_OPEN = 'SET_MORE_INFO_SLIDER_IS_OPEN',
  SET_SELECTED_VACANCY_ID = 'SET_SELECTED_VACANCY_ID',
  SET_SCHOOLS = 'SET_SCHOOLS',
  SET_READY_TO_CONNECT_MODAL_IS_OPEN = 'SET_READY_TO_CONNECT_MODAL_IS_OPEN',
  SET_CONTACTED_SCHOOL_IDS = 'SET_CONTACTED_SCHOOL_IDS',
  SET_IS_LOADING_INITIAL_FILTER_RESULTS = 'SET_IS_LOADING_INITIAL_FILTER_RESULTS',
  TOGGLE_SAVED_SCHOOL_IDS = 'TOGGLE_SAVED_SCHOOL_IDS',
  SET_IS_USER_AUTHENTICATED = 'SET_IS_USER_AUTHENTICATED',
}

export enum ConnectBadgeLabels {
  ACADEMIC_RIGOR = 'Academic rigor',
  CHARACTER_EDUCATION = 'Character education',
  COACHING_CULTURE = 'Coaching culture',
  COMPETITIVE_PAY = 'Competitive pay',
  DEI_FOCUS = 'DEI focus',
  DISTRICTWIDE = 'District-wide',
  EXPERIENCED_LEADERSHIP = 'Experienced leadership',
  FAMILY_ORIENTED = 'Family oriented',
  LEADERSHIP_OPPORTUNITIES = 'Leadership opportunities',
  LITERACY_FOCUS = 'Literacy focus',
  ONPLATFORM = 'Responds quickly',
  RECOMMENDED = 'Recommended',
  SMALL_CLASS_SIZES = 'Small class sizes',
  STEM_FOCUS = 'STEM focus',
  STRONG_ATHLETICS = 'Strong athletics',
  STRONG_SUPPORT_STAFF = 'Strong support staff',
  WHOLE_CHILD_DEVELOPMENT = 'Whole child development',
}

export enum ConnectFilterName {
  SUBCATEGORIES = 'subcategories',
  DISTANCE_RADIUS_MILES = 'distance_radius_miles',
  LOCATION = 'location',
  GRADES = 'grades',
  SCHOOLS = 'school_nces_ids',
  DISTRICTS = 'district_nces_ids',
}

export enum ConnectGatedActions {
  ALERT = 'alert', // This feature was sunset in nim-3920. It's not considered valid gated action.
  DIRECT = 'direct',
  EXPRESS_INTEREST = 'express_interest',
  SAVE_SCHOOL = 'save_school',
  SIGNUP_BUTTON = 'signup_button',
  LOGIN_BUTTON = 'login_button',
  SIGNUP_BANNER = 'signup_banner',
}
